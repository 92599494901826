<template>
  <Header :isGoBack="true" />
  <el-container class="container">
    <el-form
      ref="form"
      :model="clientForm"
      :rules="rules"
      class="w-full"
      label-position="top"
      :disabled="!caseStore.editPermission"
    >
      <div class="flex w-full justify-start mb-3">
        <span class="text-sm text-avrillo font-semibold">
          {{ route.params.id > -1 ? "UPDATE" : "ADD NEW" }}
          {{ clientForm.client_type.toUpperCase() }}</span
        >
      </div>
      <!-- Client title Start -->
      <div>
        <el-form-item prop="title" :label="`${clientForm.client_type} Title`">
          <el-input
            v-model="clientForm.title"
            size="large"
            :placeholder="`Write your ${clientForm.client_type} Title`"
            class="w-full"
          />
        </el-form-item>
      </div>
      <!-- Client title End -->

      <!-- Client name Start -->
      <div>
        <el-form-item prop="name" :label="`${clientForm.client_type} Name`">
          <el-input
            v-model="clientForm.name"
            size="large"
            :placeholder="`Write your ${clientForm.client_type} Name`"
            class="w-full"
          />
        </el-form-item>
      </div>
      <!-- Client name End -->

      <!-- Client surname Start -->
      <div>
        <el-form-item
          prop="surname"
          :label="`${clientForm.client_type} Surname`"
        >
          <el-input
            v-model="clientForm.surname"
            size="large"
            :placeholder="`Write your ${clientForm.client_type} Surname`"
            class="w-full"
          />
        </el-form-item>
      </div>
      <!-- Client surname End -->

      <!-- Client address Start -->
      <div>
        <IdealPostcode
          :labelName="`${clientForm.client_type} Address`"
          :addressModel="clientForm.address"
          modelPropPath="address."
        />
      </div>
      <!-- Client address End -->

      <!-- Button Start -->
      <div class="mt-8">
        <el-button
          class="w-full bg-primary border-0"
          type="primary"
          @click="handleClientForm"
        >
          <span class="text-xs">Save</span>
        </el-button>
      </div>
      <!-- Button End -->
    </el-form>
  </el-container>
</template>

<script setup>
import { ref } from "vue";
import { useMemorandumStore } from "@/store/MemorandumStore";
import { useCaseStore } from "@/store/CaseStore";
import { useRoute } from "vue-router";
import Header from "@/components/Header";
import IdealPostcode from "@/components/IdealPostcode/index";
import { rules } from "../utils/rules/client";
const route = useRoute();
const memorandumStore = useMemorandumStore();
const caseStore = useCaseStore();
const form = ref(null);
const clientForm = ref({
  title: "",
  name: "",
  surname: "",
  client_type: "",
  address: {
    line: "",
    postal_code: "",
    building_name: "",
    building_number: "",
    flat_number: "",
    street_name: "",
    city: "",
    county: "",
    address_block: "",
    description: "",
    latitude: "",
    longitude: "",
    uprn_number: "",
    ward: "",
    json_data: "",
    test: "",
  },
});

clientForm.value.client_type = route.params.client_type;
route.params.id = parseInt(route.params.id);

if (route.params.id > -1) {
  memorandumStore
    .getClientByIndex(route.params.id, route.params.client_type)
    .then((res) => {
      clientForm.value = res;
    });
}

const handleClientForm = () => {
  form.value.validate(async (valid) => {
    if (valid) {
      if (route.params.id > -1) {
        await memorandumStore.handleUpdateClient(
          Object.assign({}, clientForm.value),
          route.params.id
        );
      } else {
        await memorandumStore.handleNewClient(
          Object.assign({}, clientForm.value)
        );
      }
    }
  });
};

// const cleanObject = (obj) => {
// 	for (const [key] of Object.entries(obj)) {
// 		const propType = typeof obj[key]
// 		switch (propType) {
// 			case "number":
// 				obj[key] = 0
// 				break
// 			case "string":
// 				obj[key] = ""
// 				break
// 			case "boolean":
// 				obj[key] = false
// 				break
// 			case "undefined":
// 				obj[key] = undefined
// 				break
// 		}
// 	}
// }
</script>
