export const rules = {
    title: {
        required: true,
        message: 'Title field is required',
        trigger: 'blur'
    },
    name: {
        required: true,
        message: 'Name field is required',
        trigger: 'blur'
    },
    surname: {
        required: true,
        message: 'Surname field is required',
        trigger: 'blur'
    }
}
